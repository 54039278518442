<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="reporter-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" pagination search :max-items="renderMethod.itemsPerPage" :data="items">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div style="margin-right: 15px; margin-bottom: 10px">
          <datepicker type="month" v-model="dateRange2" lang="kr" style="width: 200px;"/>
        </div>

      </div>

      <template slot="thead">
        <vs-th sort-key="day">월</vs-th>
        <vs-th sort-key="totalView" class="numberFormat">전체</vs-th>
        <vs-th sort-key="pcView" class="numberFormat">PC</vs-th>
        <vs-th sort-key="mobileView" class="numberFormat">모바일</vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td><div>{{ tr.day }}</div></vs-td>
            <vs-td><div class="numberFormat" style="max-width: 100px;">{{ tr.totalView.toLocaleString() }}</div></vs-td>
            <vs-td><div class="numberFormat" style="max-width: 100px;">{{ tr.pcView.toLocaleString() }}</div></vs-td>
            <vs-td><div class="numberFormat" style="max-width: 100px;">{{ tr.mobileView.toLocaleString() }}</div></vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <div id="div-with-loading" class="news-detail-form vs-con-loading__container">
      <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="기사 상세" :active.sync="newsDetailModalActive">
        <div class="items-center">
          <div class="news-detail" id="view_con" style="margin: 20px 0px 0px;" v-html="newsDetail.data"></div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css';
import moment from 'moment';
export default {
  components: {
    // AddNewDataSidebar
    DateRangePicker,
  },
  data() {
    return {
      items: [],
      currentx: 1,
      numOfItems: 0,
      numOfPages: 0,
      newsDetail: '',
      newsDetailModalActive: false,
      dateRange2: new Date(),
      renderMethod: {
        itemsPerPage: 20,
      },
      opens: "center",//which way the picker opens, default "center", can be "left"/"right"
      locale: {
          direction: 'ltr', //direction of text
          format: 'YYYY-MM-DD', //fomart of the dates displayed
          separator: ' - ', //separator between the two ranges
          applyLabel: '선택',
          cancelLabel: '취소',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          "daysOfWeek": [
              "일",
              "월",
              "화",
              "수",
              "목",
              "금",
              "토"
          ],
          "monthNames": [
              "1월",
              "2월",
              "3월",
              "4월",
              "5월",
              "6월",
              "7월",
              "8월",
              "9월",
              "10월",
              "11월",
              "12월"
          ],
          firstDay: 0, //ISO first day of week - see moment documenations for details
          showISOWeekNumbers: true //show week numbers on each row of the calendar
      },
      isMounted: false
    };
  },
  beforeRouteLeave (to, from, next) {
    this.newsDetailModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    
    dateRange2: function() {
      
      this.searchStatisticsDesc()
    }
  },
  // computed: {
  //   currentPage() {
  //     if (this.isMounted) {
  //       return this.$refs.table.currentx;
  //     }
  //     return 0;
  //   }
  // },
  methods: {
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum;
      this.getUserList();
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
    
    searchStatisticsDesc() {

      var thisIns = this
      console.log(this.dateRange2)
      const startDate = moment(this.dateRange2).format("yyyy-MM-01")
      const endDate =  moment(this.dateRange2).format("yyyy-MM-31")
      var fixedDateRange = {
        startDate: startDate,
        endDate: endDate
      }
      console.log(fixedDateRange)

      this.$vs.loading({
        scale: 1.5
      });

      this.$http
      .post("/api/statistics/month", fixedDateRange)
      .then(function(response) {

        thisIns.$vs.loading.close()
        console.log(response)
        thisIns.items = response.data
        thisIns.numOfItems = thisIns.items.length
        thisIns.currentx = 1;
      })
      .catch(function(error) {

        thisIns.$vs.loading.close()

        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          console.log(thisIns.$router)
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
      });
    },
  },
  created() {

      var thisIns = this

      const startDate = moment(this.dateRange2).format("yyyy-MM-01")
      const endDate =  moment(this.dateRange2).format("yyyy-MM-31")
      var fixedDateRange = {
        startDate: startDate,
        endDate: endDate
      }
      console.log(fixedDateRange)

      this.$vs.loading({
        scale: 1.5
      });

      this.$http
      .post("/api/statistics/month", fixedDateRange)
      .then(function(response) {

        thisIns.$vs.loading.close()
        console.log(response)

        thisIns.items = response.data
        thisIns.numOfItems = thisIns.items.length
        thisIns.currentx = 1;
      })
      .catch(function(error) {

        thisIns.$vs.loading.close()
        
        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          console.log(thisIns.$router)
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
      });
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-reporter.scss";
@import "@/assets/scss/custom-style/vs-table-newslist.scss";
.numberFormat{
  font-family: arial;
  text-align: right;
}
</style>